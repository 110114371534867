import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

const Root = lazy(() => import("../pages/Root"));
const Homepage = lazy(() => import("../pages/Homepage"));
const Thankyou = lazy(() => import("../pages/Thankyou"));
const Privacy = lazy(() => import("../pages/Privacy"));

const routes = [
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Homepage />,
      },
      {
        path: "/thankyou",
        element: <Thankyou />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },
    ],
  },
];

const router = createBrowserRouter(routes);
export default router;
